<template>
  <div>
    <h3>待开发。。。</h3>
  </div>
</template>

<script>
export default {
  name: "Building"
}
</script>

<style scoped>

</style>